import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
import generateRandomId from "../actions/GenerateRandomId";
import { encryptPassword } from '../actions/PasswordFunctions';

// eslint-disable-next-line import/no-anonymous-default-export
export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
    getList: async (resource, params) => {
        // const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        // const query = {
        //     ...fetchUtils.flattenObject(params.filter),
        //     _sort: field,
        //     _order: order,
        //     _start: (page - 1) * perPage,
        //     _end: page * perPage,
        // };
        const url = `${apiUrl}/${resource}`;
        console.log('url', url);

        const { headers, json } = await httpClient(url);
        let safeJson = json ?? [];
        Object.keys(params.filter).forEach(filterKey => {
            const filterVal = params.filter[filterKey];
            safeJson = safeJson.filter(jsonObj => jsonObj[filterKey].includes(filterVal));
        })
        
        if (field) {
            const comparator = order === 'ASC' ? 1 : -1;
            safeJson.sort((a, b) => a[field] > b[field] ? comparator : comparator * -1)
        }
        return {
            data: safeJson,
            // This total property is required, but it can be any number and it will load
            total: safeJson?.length ?? 0
        };
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ body, json }) => {
            return {
                data: json.body ?? json
            }
        }),

    getMany: (resource, params) => {
        const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {

            return {
                data: json,
                total: 5
            };
        });
    },

    update: async (resource, params) => {
        let username;
        if (params.data.username) {
            username = params.data.username.toLowerCase();
        }
        let item;
        if (username) {
            item = {
                ...params.data,
                username
            }
        } else {
            item = {
                ...params.data
            }
        }
        const requestBody = {
            payload: {
                Item: item
            }
        }
        await httpClient(`${apiUrl}/${resource}/${params.data.id}`, {
            method: 'PUT',
            body: JSON.stringify(requestBody),
        });
        return ({ data: { ...params.data, id: params.data.id } });
    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: async (resource, params) => {
        // DynamoDB requires an ID for POST method...
        // If we are creating a new user, the username will be the ID. Otherwise, generate a random ID for a new ride mode
        let id;
        let username;
        if (params.data.username) {
            id = params.data.username.toLowerCase();
            username = params.data.username.toLowerCase();
        } else {
            id = generateRandomId(6);
        }
        let item;
        if (username) {
            item = {
                ...params.data,
                id,
                username,
                password: encryptPassword(params.data.password)
            }
        } else {
            item = {
                ...params.data,
                id
            }
        }
        const requestBody = {
            payload: {
                Item: item
            }
        }
        await httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });
        return ({
            data: { ...params.data, id },
        });
    },

    delete: async (resource, params) => {
        await httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        });
        return ({ data: params.previousData, id: params.id });
    },

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});
