import {
    Edit,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh,
    SelectInput,
    FormDataConsumer
} from "react-admin";

import { useEffect, useState } from "react";
import getDriverTypes from "../../actions/GetDriverTypes";

const CustomSettingsEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [driverTypeList, setDriverTypeList] = useState([
        { id: "1", name: "2" },
        ]);

    const onSuccess = (data) => {
        // notify(`Setting ${data.id} has been updated`);
        redirect("/customSettings");
        refresh();
    };

    useEffect(() => {
        getDriverTypes(props.adminMode).then((result) => {
            setDriverTypeList(result);
        });
        }, []);

        const GetSelectInputType = (settingScope, rest) => {
            if (settingScope === 'driverType') {
                return <SelectInput
                    source="identity"
                    label="Scope Identity"
                    choices={driverTypeList}
                    {...rest}
                />
            }
            else if (settingScope === "user") {
                return <TextInput source="identity" label="Identity" {...rest}/>
            }
            else {
                return <div></div>
            }
        }

    return (
        <Edit {...props} mutationMode="pessimistic" onSuccess={onSuccess}>
            <SimpleForm>
                <SelectInput source="settingScope" label="Setting Scope" choices={[
                    {
                        id: "default",
                        name: "Default"
                    },
                    {
                        id: "driverType",
                        name: "Driver Type"
                    },
                    {
                        id: "user",
                        name: "User"
                    },
                ]}/>    
                    <FormDataConsumer>
                        {({ formData, ...rest }) => GetSelectInputType(formData.settingScope, rest)}
                    </FormDataConsumer>
                <TextInput source="settingName" label="Setting Name"/>
                <TextInput source="settingValue" label="Setting Value"/>
            </SimpleForm>
        </Edit>
    );
};

export default CustomSettingsEdit;
