import { Show, SimpleShowLayout, TextField, NumberField, BooleanField } from 'react-admin';

const CarouselShow = (props) => {

    return (
        <Show title="Carousel View" {...props}>
            <SimpleShowLayout>
                <TextField source="type" label="Type"/>
                <TextField source="title" label="Title"/>
                <TextField source="id" label="Id"/>
                <TextField source="driverType" label="DriverType ID"/>
                <TextField source="videoUri" label="Video URL"/>
                <TextField source="detination" label="Destination"/>
                <NumberField source="duration" label="Duration (Seconds)"/>
                <TextField source="backgroundImage" label="Background Image"/>
                <NumberField source="slidePosition" label="Position"/>
                <BooleanField source="startMuted" label="Start Ad on Mute"/>
            </SimpleShowLayout>
        </Show>
    );
}

export default CarouselShow;