import { Show, SimpleShowLayout, TextField, NumberField, useRecordContext } from 'react-admin';

const NotificationsShow = (props) => {

    return (
        <Show title="Notification View" {...props}>
            <SimpleShowLayout>
                <TextField source="title" label="Title"/>
                <TextField source="id" label="Id"/>
                <TextField source="driverType" label="DriverType ID"/>
                <TextField source="triggerType" label="Trigger Type"/>
                <NumberField source="triggerDuration" label="Trigger Duration (Seconds)"/>
                <TextField source="triggerMethod" label="Trigger Method"/>
                <TextField source="displayDuration" label="Display Duration (Seconds)"/>
                <TextField source="backgroundColor" label="Background Color"/>
                <TextField source="contentType" label="Content Type"/>
                <TextField source="contentBody" label="Content"/>
                <TextField source="microSurveyQuestion" label="Micro Survey Question"/>
            </SimpleShowLayout>
        </Show>
    );
}

export default NotificationsShow;