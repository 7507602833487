import {
    Create,
    TextInput,
    SimpleForm,
    useNotify,
    useRefresh,
    useRedirect,
    TabbedForm,
    FormTab,
    SimpleFormIterator,
    ArrayInput,
    SelectInput,
    NumberInput,
    FormDataConsumer,
    BooleanInput   
} from 'react-admin';
import {useEffect, useState} from "react";
import {getDriverTypes, getRideModes} from "../../actions/GetDriverTypes";
import { makeStyles } from '@material-ui/core/styles'

const customStyles = makeStyles({
    input: {display: 'block'},
})
const emptyRideMode = {id: undefined, name: 'Stay in Carousel'};

const CarouselCreate = (props) => {
    const classes = customStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [driverTypeList, setDriverTypeList] = useState([{id: '1', name: 'This is a blank value'}]);
    const [rideModeList, setRideModes] = useState([emptyRideMode]);

    const onSuccess = ({ data }) => {
        notify(`New carousel ${data.id} has been created`);
        redirect("/carousels");
        refresh();
    }

    useEffect(async () => {
        const [driverTypes, rideModes] = await Promise.all([getDriverTypes(props.adminMode), getRideModes(props.adminMode)]);
        setDriverTypeList(driverTypes);
        setRideModes(rideModes)
    }, []);

    const getAvailableRideModes = (rideModes, driverTypesList, driverType) => {
        const driverTypes = driverTypesList.filter(dt => dt.name === driverType || dt.id === driverType);
        if (driverTypes) {
            const driverTypeName = driverTypes[0]?.name;
            return [emptyRideMode, ...rideModes.filter(rm => rm.driverType === driverTypeName)];
        }
        return [];
    }
    return (
        <Create {...props} onSuccess={onSuccess}>
            <SimpleForm>
                <SelectInput source="type" label="Type" choices={[
                    {
                        id: "Content",
                        name: "Content"
                    },
                    {
                        id: "Ad",
                        name: "Ad"
                    },
                ]}/>
                <FormDataConsumer>
                 {({ formData, ...rest }) => formData.type === 'Ad' ? 
                      (
                        <div style={{width: 250}}>
                            <TextInput source="title" {...rest} />
                            <NumberInput className={classes.input} source="duration" label="Duration (Seconds)"/>
                            <SelectInput source="driverType" label="Driver Type" choices={driverTypeList}/>
                            <NumberInput className={classes.input} source="maxAdDuration" label="Max Ad Duration (Seconds)"/>
                            <NumberInput className={classes.input} source="slidePosition" label="Position"/>
                            <TextInput className={classes.input} source="backgroundImage" label="Background Image"/>
                            <BooleanInput className={classes.input} source="startMuted" label="Start Ad on Mute"/>
                            <SelectInput
                                source="destination"
                                label="Destination"
                                choices={
                                    formData.driverType ? getAvailableRideModes(rideModeList, driverTypeList, formData.driverType) : []
                                }
                                {...rest}
                            />
                        </div>
                      ) :
                      (
                        <div style={{width: 250}}>
                            <TextInput className={classes.input} source="title"/>
                            <SelectInput fullWidth={true} className={classes.input} source="driverType" label="Driver Type" choices={driverTypeList} emptyText={'Choose a driver type'}/>
                            <NumberInput className={classes.input} source="duration" label="Duration (Seconds)"/>
                            <TextInput className={classes.input} source="backgroundImage" label="Background Image"/>
                            <TextInput className={classes.input} source="videoUri" label="Video URL"/>
                            <NumberInput className={classes.input} source="slidePosition" label="Position"/>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <SelectInput
                                        source="destination"
                                        label="Destination"
                                        choices={
                                            formData.driverType ? getAvailableRideModes(rideModeList, driverTypeList, formData.driverType) : []
                                        }
                                        {...rest}
                                    />
                                )}
                            </FormDataConsumer>
                        </div>
                      )
                 }
             </FormDataConsumer>
                
            </SimpleForm>
        </Create>
    );
}

export default CarouselCreate;
