import {
  BooleanField,
  Datagrid,
  List,
  useRecordContext,
  EditButton
} from "react-admin";

import { useEffect, useState } from "react";
import getDriverTypes from "../../actions/GetDriverTypes";

/**
 * id: number;
 * driverType: string;
 * url: string
 * */

const PublicaAdsList = (props) => {
  const [driverTypeList, setDriverTypeList] = useState([{ id: "", name: "" }]);
  useEffect(() => {
    getDriverTypes(props.adminMode).then((result) => {
      setDriverTypeList(result);
    });
  }, []);

  const DriverType = (props) => {
    const record = useRecordContext(props);
    let r = "";

    driverTypeList.forEach((item) => {
      if (record.driverType === item.id) {
        r = item.name;
      }
    });
    return <span>{r}</span>;
  };

  return (
    <List {...props}>
      <Datagrid currentSort={null}>
        <DriverType source="driverType" label="Driver Type" />
        <BooleanField source="isActive" label="Active Status" />
        {/* <ShowButton /> */}
        <EditButton /> 
      </Datagrid>
    </List>
  );
};

export default PublicaAdsList;
