import {
  Create,
  TextInput,
  SimpleForm,
  useNotify,
  useRefresh,
  useRedirect,
  SelectInput,
} from "react-admin";

import { useEffect, useState } from "react";
import getDriverTypes from "../../actions/GetDriverTypes";

const PremiumAdsCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [driverTypeList, setDriverTypeList] = useState([
    { id: "1", name: "2" },
  ]);

  const onSuccess = ({ data }) => {
    notify(`New premium ads ${data.id} has been created`);
    redirect("/premium_ads");
    refresh();
  };

  useEffect(() => {
    getDriverTypes(props.adminMode).then((result) => {
      setDriverTypeList(result);
    });
  }, []);

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source="url" />
        <SelectInput
          source="driverType"
          label="Driver Type"
          choices={driverTypeList}
        />
      </SimpleForm>
    </Create>
  );
};

export default PremiumAdsCreate;
