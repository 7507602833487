import { Edit, TabbedForm, FormTab, TextInput, ArrayInput, SimpleFormIterator, SelectInput, useNotify, useRefresh, useRedirect } from 'react-admin';
import {useEffect, useState} from "react";
import getDriverTypes from "../../actions/GetDriverTypes";

const SurveyEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [driverTypeList, setDriverTypeList] = useState([{id: '1', name: '2'}]);

    const onSuccess = ({ data }) => {
        notify(`New map ${data.id} has been created`);
        redirect("/surveys");
        refresh();
    }

        useEffect(() => {
        getDriverTypes(props.adminMode).then((result => {
            setDriverTypeList(result);
        }))
    }, []);

    return (
        <Edit {...props} mutationMode="pessimistic" onSuccess={onSuccess}>
            <TabbedForm>
                <FormTab label="Survey Setup">
                    <TextInput source="name" label="Name"/>
                    <SelectInput source="driverType" label="Driver Type" choices={driverTypeList}/>
                    <TextInput source="background" label="Background"/>
                </FormTab>
                <FormTab label="Questions" path="questions">
                        <ArrayInput source="questions" label="Questions">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Question"/>
                            <SelectInput source="answerType" label="Answer Type" choices={[
                            {
                            id: "Multiple Choice",
                            name: "Multiple Choice"
                            },
                            {
                            id: "Yes/No",
                            name: "Yes/No"
                            },
                            ]}/>
                            <ArrayInput source="options" label="Options">
                                <SimpleFormIterator>
                                    <TextInput label="Answer"/>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}
export default SurveyEdit;