import {List, Datagrid, TextField, ShowButton, useRecordContext, EditButton, NumberField, TextInput, SelectInput, BooleanField} from 'react-admin';
import {useEffect, useState} from "react";
import getDriverTypes from "../../actions/GetDriverTypes";


/**
 * id: number;
 * driverType: string;
 * isStartScreen: boolean
 * color: string
 * brandImageSource: string
 * greetingText: string (e
 * */

const DriverType = (props) => {
    const record = useRecordContext(props);
    let r = "";

    props.driverTypeList.forEach(item => {
        if (record.driverType === item.id) {
            r = item.name;
        }
    });
    return <span>{r}</span>;
}

const postFilters = (driverTypeList) => {
    return [
        <TextInput label="Title" source="title" defaultValue="" />,
        <SelectInput label="DriverType" source="driverType" choices={driverTypeList}/>
    ]
};

const CustomCarouselList = (props) => {
    // const [isLoaded, setIsLoaded] = useState(false);
    // const [mappedData, setMappedData] = useState([]);
    const [driverTypeList, setDriverTypeList] = useState([{id: '1', name: '2'}]);
    const [selectedDriverTypeId, setNewDriverTypeId] = useState();
    console.log('Render Carousel List')
    // const { data, loaded } = useGetList('carousels');
    // if (loaded && !isLoaded) {
    //     console.log('setting isLoaded to true')
    //     setIsLoaded(true);
    // }
    // if (isLoaded && !mappedData.length) {
    //     console.log('setting mapped data')
    //     setMappedData(Object.values(data))
    // }
    useEffect(() => {
        console.log('getting driverTypes')
        getDriverTypes(props.adminMode).then((result => {
            setDriverTypeList(result);
        }))
    }, []);

    return (
        <List sort={{ field: 'slidePosition', order: 'ASC' }} filters={postFilters(driverTypeList)} {...props}>
            <Datagrid>
                <TextField source="type" label="Type"/>
                <TextField source="title" label="Title"/>
                <TextField source="destination" label="destination"/>
                <DriverType driverTypeList={driverTypeList} source="driverType" label="Driver Type"/>
                <NumberField source="slidePosition" label="Position"/>
                <TextField source="detination" label="Destination"/>
                <BooleanField source="startMuted" label="Start Ad on Mute"/>
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );

     
}

export default CustomCarouselList;
