import { Show, SimpleShowLayout, TextField } from "react-admin";

const CustomSettingsShow = (props) => {
  return (
    <Show title="CustomSettings View" {...props}>
      <SimpleShowLayout>
          <TextField source="settingScope" label="Scope" />
          <TextField source="identity" label="Scope identity" />
          <TextField source="settingName" label="Setting Name" />
          <TextField source="settingValue" label="Setting Value" />
      </SimpleShowLayout>
    </Show>
  );
};

export default CustomSettingsShow;
