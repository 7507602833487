import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';

const UsersShow = (props) => {
    console.log(props);
    return (
        <Show title="User View" {...props}>
            <SimpleShowLayout>
                <TextField source="id" label="Id"/>
                <TextField source="username" label="Username"/>
                <TextField source="password" label="Password"/>
                <TextField source="imei" label="IMEI"/>
                <TextField source="macAddress" label="MAC"/>
                <TextField source="driverType" label="Driver Type"/>
                <BooleanField source="isActive" label="Active Status"/>
            </SimpleShowLayout>
        </Show>
    );
}

export default UsersShow;