import {List, Datagrid, TextField, EditButton, ShowButton, useRecordContext} from 'react-admin';
import {useEffect, useState} from "react";
import getDriverTypes from "../../actions/GetDriverTypes";

/**
 * id: number;
 * name: string;
 * */

const SurveyList = (props) => {

    const [driverTypeList, setDriverTypeList] = useState([{id: '', name: ''}]);
    useEffect(() => {
        getDriverTypes(props.adminMode).then((result => {
            setDriverTypeList(result);
        }))
    }, []);

    const DriverType = (props) => {
        const record = useRecordContext(props);
        let r = "";

        driverTypeList.forEach(item => {
            if (record.driverType === item.id) {
                r = item.name
            }
        });
        return <span>{r}</span>;
    }

    return (
        <List {...props}>
            <Datagrid currentSort={null}>
                <TextField source="id" label="id"/>
                <TextField source="name" label="Name"/>
                <DriverType source="driverType" label="Driver Type"/>
                <TextField source="background" label="Background"/>
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
}

export default SurveyList;
