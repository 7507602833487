import { Edit, SimpleForm, TextInput, BooleanInput, SelectInput, PasswordInput, useNotify, useRefresh, useRedirect } from 'react-admin';
import { decryptPassword, encryptPassword } from '../../actions/PasswordFunctions';
import {useEffect, useState} from "react";
import getDriverTypes from "../../actions/GetDriverTypes";

const UsersEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [driverTypeList, setDriverTypeList] = useState([{id: '1', name: '2'}]);

    const onSuccess = ({ data }) => {
        notify(`User ${data.id} has been updated`);
        redirect("/users");
        refresh();
    }

    useEffect(() => {
        getDriverTypes(props.adminMode).then((result => {
            setDriverTypeList(result);
        }))
    }, []);


    return (
        <Edit {...props} mutationMode="pessimistic" onSuccess={onSuccess}>
            <SimpleForm>
                <TextInput disabled source="id" label="Id"/>
                <TextInput source="username" label="Username"/>
                <PasswordInput source="password" label="Password" parse={password => encryptPassword(password)} format={hash => decryptPassword(hash)}/>
                <TextInput source="imei" label="IMEI"/>
                <TextInput source="macAddress" label="MAC"/>
                <SelectInput source="driverType" label="Driver Type" choices={driverTypeList}/>
                <BooleanInput source="isActive" label="Active Status"/>
            </SimpleForm>
        </Edit>
    );
}

export default UsersEdit;
