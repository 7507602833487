import axios from "axios";

const submitReportingQuery = async (params) => {
    const { startDate, endDate, tabletId } = params;
    let queryURL;
    if (tabletId) {
        queryURL = `${process.env.REACT_APP_RIDEMODES_REPORTING_URL}?start=${startDate}&end=${endDate}&tabletId=${tabletId}`;
    } else {
        queryURL = `${process.env.REACT_APP_RIDEMODES_REPORTING_URL}?start=${startDate}&end=${endDate}`;
    }
    const config = {
        headers: {
            "X-Api-Key": process.env.REACT_APP_RIDEMODES_API_KEY_DEV
        }
    }
    const res = await axios.get(queryURL, config);
    return res.data;
}

export default submitReportingQuery;