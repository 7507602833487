import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

const DriverTypeEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Driver type ${data.id} has been updated`);
    redirect("/drivertypes");
    refresh();
  };

  return (
    <Edit {...props} mutationMode="pessimistic" onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          source="driverType"
          label="Driver Type"
          placeholder={"Text"}
        />
        <BooleanInput source="isStartScreen" label="Is Start Screen" />
        <BooleanInput source="isCarousel" label="Is Carousel" />
        <TextInput
          source="color"
          label="Color"
          placeholder={"For example #fff or #ffffff"}
        />
        <TextInput
          source="brandImageSource"
          label="Brand Image Source"
          placeholder={"Url of jpg or png image"}
        />
        <TextInput
          source="greetingText"
          label="Greeting text"
          placeholder={"Text"}
        />
        <TextInput
          source="subheadingText"
          label="Subheading Text"
          placeholder={"Text"}
        />
        <TextInput
          source="sessionTimeout"
          label="Session timeout"
          placeholder={"In minutes"}
        />
        <BooleanInput
          source="shouldPlayAds"
          label="Play Ads"
        />
        <NumberInput 
          source="maxAdDuration"
          label="Max Ad Duration (Seconds)"
        />
        <NumberInput 
          source="playsBetweenAds"
          label="Number of content plays between ads"
        />

      </SimpleForm>
    </Edit>
  );
};

export default DriverTypeEdit;
