import axios from "axios";
import { Buffer } from 'buffer';
const ACCESS_USERNAME='ivee';
const ACCESS_PASSWORD='ivEEt4c2024';

const delay = (t, v) => {
    return new Promise(resolve => setTimeout(resolve, t, v));
}

export const requestWithRetry = async (func, retryCount = 0, retryMax = 5, ...args) => {
    try {
        return await func(...args);
      } catch (error) {
        console.log('RETRY FAILED WITH ERROR: ' + error)
        if(retryCount = retryMax) {
            console.log("Failed to complete response: " + JSON.stringify(error))
            return;
        }
        await delay(1000);
        return requestWithRetry(func, retryCount + 1, retryMax, ...args);
      }
    }

export const initializeAccessApi = async () => {
    const result = await axios.post('https://t4c.accesstwine.com:5017/login', '<data><name>test</name><type>1</type><deviceid>1</deviceid></data>', {
        headers: {
          'Content-Type': 'application/xml',
          'Authorization-Type': 'register',
          'Authorization': 'Basic ' + Buffer.from(ACCESS_USERNAME + ':' + ACCESS_PASSWORD).toString('base64')
        }
    });
    if (result.status !== 200) {
        throw new Error('Failed to login into Access API: ' + JSON.stringify(result.error))
    }
    const gidRegex = /<gid>(.+?)<\/gid>/;
    const gid = gidRegex.exec(result.data)[1];
    const tokenRegex = /<token>(.+?)<\/token>/;
    const token = tokenRegex.exec(result.data)[1];
    return {token, gid};
}

export const fetchAccessApiInfo = async (gid, token, limit) => {
    console.log('fetching access info with gid ' + gid + 'token: ' + token + ' limit: ' + limit)
    const result = await axios.get('https://t4c.accesstwine.com:5011/paid_live_tv', {
        headers: {
          'Accept': 'application/json',
          'Authorization-Type': 'gid',
          'Authorization': 'Basic ' + Buffer.from(gid + ':' + token).toString('base64')
        },
        params: {
            limit: limit
        }
    });
    if (result.status !== 200) {
        throw new Error('Failed to login into Access API: ' + JSON.stringify(result.error))
    }

    return result.data;
}