import {
  BooleanInput,
  Create,
  SelectInput,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";

import { useEffect, useState } from "react";
import getDriverTypes from "../../actions/GetDriverTypes";

const PublicaAdsCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [driverTypeList, setDriverTypeList] = useState([
    { id: "1", name: "2" },
  ]);

  const onSuccess = ({ data }) => {
    notify(`New publica ads ${data.id} has been created`);
    redirect("/publica_ads");
    refresh();
  };

  useEffect(() => {
    getDriverTypes(props.adminMode).then((result) => {
      setDriverTypeList(result);
    });
  }, []);

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <SelectInput
          source="driverType"
          label="Driver Type"
          choices={driverTypeList}
        />
        <BooleanInput source="isActive" label="Active Status" />
      </SimpleForm>
    </Create>
  );
};

export default PublicaAdsCreate;
