import { makePickerWithState } from '@material-ui/pickers';
import { Create, SimpleForm, TextInput, BooleanInput, SelectInput, PasswordInput, useNotify, useRefresh, useRedirect } from 'react-admin';

const MapCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify(`New map ${data.id} has been created`);
        redirect("/maps");
        refresh();
    }

    return (
        <Create {...props} onSuccess={onSuccess}>
            <SimpleForm>
                <TextInput source="street" label="Street Name"/>
                <TextInput source="radius" label="Radius"/>
                <TextInput source="sponsoredMap" label="Sponsored Content"/>
            </SimpleForm>
        </Create>
    );
}

export default MapCreate;