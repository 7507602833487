import {
  Edit,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

const PremiumAdsEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Premium Ads ${data.id} has been updated`);
    redirect("/premium_ads");
    refresh();
  };

  return (
    <Edit {...props} mutationMode="pessimistic" onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          source="driverType"
          label="Driver Type"
          placeholder={"Text"}
        />
        <TextInput source="url" label="URL" placeholder={"Url of the video"} />
      </SimpleForm>
    </Edit>
  );
};

export default PremiumAdsEdit;
