import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";

const PublicaAdsEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Publica Ads ${data.id} has been updated`);
    redirect("/publica_ads");
    refresh();
  };

  return (
    <Edit {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          source="driverType"
          label="Driver Type"
          placeholder={"Text"}
        />
        <BooleanInput source="isActive" label="Active Status" />
      </SimpleForm>
    </Edit>
  );
};

export default PublicaAdsEdit;
