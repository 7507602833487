import { BooleanField, NumberField, Show, SimpleShowLayout, TextField } from "react-admin";

const DriverTypeShow = (props) => {
  return (
    <Show title="Driver Types View" {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="id" />
        <TextField source="driverType" label="Driver Type" />
        <BooleanField source="isStartScreen" label="Is Start Screen" />
        <BooleanField source="isCarousel" label="Is Carousel" />
        <TextField source="color" label="Color" />
        <TextField source="brandImageSource" label="Brand Image Source" />
        <TextField source="greetingText" label="Greeting text" />
        <TextField source="subheadingText" label="Subheading text" />
        <TextField source="sessionTimeout" label="Session timeout" />
        <BooleanField
          source="shouldPlayAds"
          label="Play Ads"
        />
        <NumberField 
          source="maxAdDuration"
          label="Max Ad Duration (Seconds)"
        />
        <NumberField 
          source="playsBetweenAds"
          label="Number of content plays between ads"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default DriverTypeShow;
