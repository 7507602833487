import { Show, SimpleShowLayout, TextField } from "react-admin";

const PremiumAdsShow = (props) => {
  return (
    <Show title="Premium Ads View" {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <TextField source="driverType" label="DriverType ID" />
        <TextField source="url" label="Url" />
      </SimpleShowLayout>
    </Show>
  );
};

export default PremiumAdsShow;
