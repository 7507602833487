import { Show, SimpleShowLayout, TextField} from 'react-admin';

const MapShow = (props) => {
    return (
        <Show title="Map View" {...props}>
            <SimpleShowLayout>
                <TextField source="street" label="Street Name"/>
                <TextField source="radius" label="Radius"/>
                <TextField source="sponsoredMap" label="Sponsored Content"/>
            </SimpleShowLayout>
        </Show>
    );
}

export default MapShow;