import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  BooleanField,
  NumberField,
} from "react-admin";

/**
 * id: number;
 * driverType: string;
 * isStartScreen: boolean
 * color: string
 * brandImageSource: string
 * greetingText: string (e
 * */

const DriverTypeList = (props) => {
  return (
    <List {...props}>
      <Datagrid currentSort={null}>
        <TextField source="id" label="id" />
        <TextField source="driverType" label="Driver Type" />
        <BooleanField source="isStartScreen" label="Is Start Screen" />
        <BooleanField source="isCarousel" label="Is Carousel" />
        <BooleanField source="shouldPlayAds" label="Play Ads"/>
        <NumberField source="maxAdDuration" label="Max Ad Duration (Seconds)"/>
        <NumberField source="playsBetweenAds" label="Number of content plays between ads"/>
        <TextField source="color" label="Color" />
        <TextField source="brandImageSource" label="Brand Image Source" />
        <TextField source="greetingText" label="Greeting text" />
        <TextField source="subheadingText" label="Subheading text" />
        <NumberField source="sessionTimeout" label="Session timeout" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default DriverTypeList;
