import {
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  TextField,
  NumberField,
  ArrayField,
  ChipField,
  UrlField,
  Datagrid,
  BooleanField,
} from "react-admin";

const RideModesShow = (props) => {
  return (
    <Show title="Ride Mode View" {...props}>
      <SimpleShowLayout>
        <TabbedShowLayout>
          <Tab label="Identifiers">
            <TextField disabled source="id" label="Ride Mode Id" />
            <TextField source="name" label="Ride Mode Name" />
            <TextField source="nameImage" label="Ride Mode Image" />
            <TextField
              source="nameInactiveImage"
              label="Ride Mode Inactive Image"
            />
            <TextField source="nameSize" label="Ride Mode Image Size" />
            <TextField source="driverType" label="Driver Type" />
            <BooleanField source="isFeatured" label="Featured" />
          </Tab>
          <Tab label="Rows" path="page-rows">
            <TextField source="sponsoredApp.rowPosition" label="Position" />
            <TextField source="sponsoredApp.rowTitle" label="Row Title" />
            <TextField source="sponsoredApp.rowDetails" label="Row Details" />
          </Tab>
          <Tab label="Music Player" path="music-player">
            <ArrayField
              source="musicPlayer.playlistGroups"
              label="Playlist Groups"
            >
              <Datagrid>
                <TextField source="title" label="Title" />
                <UrlField source="imagePath" label="Image URL" />
                <UrlField source="srcPath" label="Source URL" />
                <ArrayField source="songs" label="Songs">
                  <Datagrid>
                    <TextField source="title" label="Title" />
                    <TextField source="artist" label="Artist" />
                    <TextField source="genre" label="Genre" />
                    <TextField source="cover" label="Cover Image" />
                    <TextField source="src" label="Audio File" />
                  </Datagrid>
                </ArrayField>
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="Sponsored App" path="sponsored-app">
            <ChipField source="sponsoredApp.name" label="Name" />
            <ChipField source="sponsoredApp.type" label="Application Type" />
            <TextField
              source="sponsoredApp.style.minHeight"
              label="Minimum Height"
            />
            <TextField
              source="sponsoredApp.style.minWidth"
              label="Minimum Width"
            />
            <TextField
              source="sponsoredApp.style.background"
              label="Background"
            />
            <TextField source="sponsoredApp.sponsorColor" label="Color" />
          </Tab>
          <Tab label="Left Sponsor" path="left-sponsor">
            <TextField source="sponsors.left.title" label="Title" />
            <UrlField source="sponsors.left.image" label="Image" />
            <UrlField source="sponsors.left.url" label="Content URL" />
            <UrlField source="sponsors.left.anchor" label="Anchor URL" />
            <NumberField source="sponsors.left.imageWidth" label="Width" />
            <NumberField source="sponsors.left.imageHeight" label="Height" />
          </Tab>
          <Tab label="Right Sponsor" path="right-sponsor">
            <TextField source="sponsors.right.title" label="Title" />
            <UrlField source="sponsors.right.image" label="Image" />
            <UrlField source="sponsors.right.url" label="Content URL" />
            <UrlField source="sponsors.right.anchor" label="Anchor URL" />
            <NumberField source="sponsors.right.imageWidth" label="Width" />
            <NumberField source="sponsors.right.imageHeight" label="Height" />
          </Tab>
          <Tab label="Bottom Sponsor" path="bottom-sponsor">
            <TextField source="sponsors.bottom.title" label="Title" />
            <TextField source="sponsors.bottom.image" label="Image" />
            <NumberField source="sponsors.bottom.imageWidth" label="Width" />
            <NumberField source="sponsors.bottom.imageHeight" label="Height" />
          </Tab>
          <Tab label="Sponsor Page" path="sponsor-page">
            <TextField source="sponsors.page.title" label="Title" />
            <TextField source="sponsors.page.body" label="Body" />
            <UrlField source="sponsors.page.image" label="Image" />
            <UrlField source="sponsors.page.QRCodeValue" label="QR Code" />
            <TextField
              source="sponsors.page.QRCodeMessage"
              label="QR Message"
            />
            <TextField source="sponsors.page.QRCodeLogo" label="QR Logo" />
            <NumberField
              source="sponsors.page.imageHeight"
              label="Image Height"
            />
            <NumberField
              source="sponsors.page.imageWidth"
              label="Image Width"
            />
          </Tab>
          <Tab label="Pay Demo" path="pay-demo">
            <TextField
              source="sponsors.payDemo.headerLeft"
              label="Left Header"
            />
            <TextField
              source="sponsors.payDemo.headerRight"
              label="Right Header"
            />
            <TextField source="sponsors.payDemo.bodyLeft" label="Left Body" />
            <TextField source="sponsors.payDemo.bodyRight" label="Right Body" />
            <TextField
              source="sponsors.payDemo.buttonText"
              label="Button Text"
            />
            <UrlField
              source="sponsors.payDemo.QRCodeValue"
              label="QR Code Value"
            />
            <UrlField
              source="sponsors.payDemo.backgroundImage"
              label="Background Image"
            />
          </Tab>
          <Tab label="Choose Pay" path="choose-pay">
            <TextField source="sponsors.choosePay.header" label="Header" />
            <TextField source="sponsors.choosePay.body" label="Body" />
            <UrlField
              source="sponsors.choosePay.QRCodeValue"
              label="QRCodeValue"
            />
            <TextField
              source="sponsors.choosePay.instructionsOne"
              label="Instructions #1"
            />
            <TextField
              source="sponsors.choosePay.instructionsTwo"
              label="Instructions #2"
            />
          </Tab>
          <Tab label="Choose Demo" path="choose-demo">
            <TextField
              source="sponsors.chooseDemo.topHeader"
              label="Top Header"
            />
            <TextField
              source="sponsors.chooseDemo.bottomHeader"
              label="Bottom Header"
            />
          </Tab>
          <Tab label="Below Fold Rows" path="below-fold-rows">
            <TextField source="sponsoredApp.rowTitle" label="Row Title" />
            <TextField source="sponsoredApp.rowType" label="Type" />
            <NumberField source="sponsoreredApp.imageHeight" label="Height" />
          </Tab>
          <ArrayField source="belowFoldRows">
            <Datagrid>
              <TextField source="title" label="Title" />
              <NumberField source="imageWidth" label="Width" />
              <NumberField source="imageHeight" label="Height" />
              <ArrayField source="videos" label="Videos">
                <Datagrid>
                  <TextField source="title" label="Title" />
                  <UrlField source="videoUrl" label="Video URL" />
                  <UrlField source="image" label="Image" />
                  <TextField source="sponsoredAppName" label="SponsoredAppName"/>
                </Datagrid>
              </ArrayField>
              <ArrayField source="access" label="Access">
                <Datagrid>
                  <TextField source="title" label="Title" />
                  <UrlField source="videoTitle" label="Video title to play" />
                  <UrlField source="image" label="Image" />
                  <TextField source="sponsoredAppName" label="SponsoredAppName"/>
                  <TextField source="url" label="Video Url"/>
                  <TextField source="accessImage" label="Access Image Link"/>
                </Datagrid>
              </ArrayField>
              <ArrayField source="html" label="HTML">
                <Datagrid>
                  <TextField source="title" label="Title" />
                  <UrlField soure="htmlUrl" label="HTML URL" />
                  <UrlField source="htmlImage" label="Image" />
                  <TextField source="sponsoredAppName" label="SponsoredAppName"/>
                </Datagrid>
              </ArrayField>
              <ArrayField source="twine" label="Twine">
                <Datagrid>
                  <TextField source="title" label="Title" />
                  <UrlField source="image" label="Image" />
                  <TextField source="twineType" label="Twine Type" />
                  <TextField source="twineName" label="Twine Name" />
                  <UrlField source="videoUrl" label="Video URL" />
                  <TextField source="sponsoredAppName" label="SponsoredAppName"/>
                </Datagrid>
              </ArrayField>
            </Datagrid>
          </ArrayField>
          <Tab label="Audio Source" path="audio-source">
            <ArrayField source="sponsoredApp.audioSource.songs" label="Songs">
              <Datagrid>
                <TextField source="mode" label="Mode" />
                <TextField source="cover" label="Cover Image" />
                <TextField source="title" label="Title" />
                <TextField source="artist" label="Artist" />
                <TextField source="genre" label="Genre" />
                <TextField source="src" label="Audio File" />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="Video Source" path="video-source">
            <ArrayField source="sponsoredApp.videoSource.videos" label="Videos">
              <Datagrid>
                <TextField source="title" label="Title" />
                <UrlField source="url" label="Video URL" />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="Image Source" path="image-source">
            <ArrayField source="sponsoredApp.imageSource.images" label="Images">
              <Datagrid>
                <TextField source="title" label="Title" />
                <UrlField source="imageUrl" label="Image URL" />
                <UrlField source="navUrl" label="Link URL" />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="HTML Source" path="html-source">
            <ArrayField source="sponsoredApp.htmlSource.html" label="HTML">
              <Datagrid>
                <TextField source="title" label="Title" />
                <UrlField source="htmlImage" label="HTML Thumbnail" />
                <UrlField source="htmlUrl" label="HTML Content" />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};

export default RideModesShow;
