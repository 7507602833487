import { Show, SimpleShowLayout, TextField } from "react-admin";

const PublicaAdsShow = (props) => {
  return (
    <Show title="Publica Ads View" {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <TextField source="driverType" label="DriverType ID" />
        <TextField source="isActive" label="Active Status" />
      </SimpleShowLayout>
    </Show>
  );
};

export default PublicaAdsShow;
