import { List, Datagrid, TextField, ArrayField, ChipField, EditButton, ShowButton, BooleanField } from 'react-admin';

const RideModesList = props => (
    <List {...props}>
        <Datagrid currentSort={null}>
            <TextField source="id" label="Id"/>
            <TextField source="name" label="Name"/>
            <TextField source="driverType" label="Driver Type"/>
            <ArrayField source="musicPlayer.playlistGroups" label="Playlist Groups">
                <Datagrid>
                    <TextField source="title"/>
                </Datagrid>
            </ArrayField>
            <ChipField source="sponsoredApp.name" label="Sponsored App Name"/>
            <TextField source="sponsors.left.title" label="Left Sponsor"/>
            <TextField source="sponsors.right.title" label="Right Sponsor"/>
            <TextField source="sponsors.bottom.title" label="Bottom Sponsor"/>
            <TextField source="sponsors.page.title" label="Sponsor Page"/>
            <ArrayField source="belowFoldRows" label="Below Fold Rows">
                <Datagrid>
                    <TextField source="title" label="Title"/>
                </Datagrid>
            </ArrayField>
            <BooleanField source="isFeatured" label="Featured"/>
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

export default RideModesList;