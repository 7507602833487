import axios from "axios";
const getDriverTypes = async (adminMode) => {
    let queryURL;

    if (adminMode === 'rideModesConfigProd') {
        queryURL = `${process.env.REACT_APP_RIDEMODES_CONFIG_URL_PROD}/drivertypes`;
    } else {
        queryURL = `${process.env.REACT_APP_RIDEMODES_CONFIG_URL_PROD}/drivertypes`;
    }

    const config = {
        headers: {
            "X-Api-Key": adminMode === 'rideModesConfigProd' ? process.env.REACT_APP_RIDEMODES_API_KEY_PROD : process.env.REACT_APP_RIDEMODES_API_KEY_DEV
        }
    }
    const res = await axios.get(queryURL, config);

    if (res.data && res.data.length > 0) {
        return res.data.map((item) => {
            let obj = {id: '', name: ''};

            if (typeof item.id === 'string') {
                obj.id = item.id;
            }

            if (typeof item.driverType === 'string') {
                obj.name = item.driverType;
            }

            return obj;
        });

    }

    return [{}];
}


const getRideModes = async () => {
    const queryURL = `${process.env.REACT_APP_RIDEMODES_CONFIG_URL_PROD}/ridemodes`;
    const config = {
        headers: {
            "X-Api-Key": process.env.REACT_APP_RIDEMODES_API_KEY_PROD
        }
    }
    const res = await axios.get(queryURL, config);
    console.log(res.data)
    if (res.data && res.data.length > 0) {
        return res.data.map((item) => {
            let obj = {id: '', name: ''};

            if (typeof item.id === 'string') {
                obj.id = item.id;
            }

            if (typeof item.driverType === 'string') {
                obj.name = item.name;
            }

            if (typeof item.driverType === 'string') {
                obj.driverType = item.driverType;
            }

            return obj;
        });

    }

    return [{}];
}

export {
    getRideModes,
    getDriverTypes
}

export default getDriverTypes;
