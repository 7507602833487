import { makePickerWithState } from '@material-ui/pickers';
import {
    Edit,
    TextInput,
    SimpleForm,
    useNotify,
    FormTab,
    useRefresh,
    useRedirect,
    SelectInput,
    NumberInput,
    FormDataConsumer,
    BooleanInput
} from 'react-admin';

import {useEffect, useState} from "react";
import { getDriverTypes, getRideModes } from '../../actions/GetDriverTypes';
const emptyRideMode = {id: undefined, name: 'Stay in Carousel'};
const CarouselEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [driverTypeList, setDriverTypeList] = useState([{id: '1', name: '2'}]);
    const [rideModeList, setRideModes] = useState([emptyRideMode]);

    const onSuccess = ({ data }) => {
        notify(`New carousel ${data.id} has been created`);
        redirect("/carousels");
        refresh();
    }

    useEffect(async () => {
        const [driverTypes, rideModes] = await Promise.all([getDriverTypes(props.adminMode), getRideModes(props.adminMode)]);
        setDriverTypeList(driverTypes);
        setRideModes(rideModes)
    }, []);

    const getAvailableRideModes = (rideModes, driverTypesList, driverType) => {
        const driverTypes = driverTypesList.filter(dt => dt.name === driverType || dt.id === driverType);
        if (driverTypes) {
            const driverTypeName = driverTypes[0]?.name;
            return [emptyRideMode,...rideModes.filter(rm => rm.driverType === driverTypeName)];
        }
        return [];
    }

    return (
        <Edit {...props} mutationMode="pessimistic" onSuccess={onSuccess}>
            <SimpleForm>
                <TextInput disabled source="id" label="Id"/>
                <SelectInput source="type" label="Type" choices={[
                    {
                        id: "Content",
                        name: "Content"
                    },
                    {
                        id: "Ad",
                        name: "Ad"
                    },
                ]}/>
                <TextInput source="title"/>
                <SelectInput source="driverType" label="Driver Type" choices={driverTypeList}/>
                <NumberInput source="duration" label="Duration (Seconds)"/>
                <TextInput source="backgroundImage" label="Background Image"/>
                <TextInput source="videoUri" label="Video URL"/>
                <NumberInput source="slidePosition" label="Position"/>
                <BooleanInput source="startMuted" label="Start Ad on Mute"/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <SelectInput
                            source="destination"
                            label="Destination"
                            choices={
                                formData.driverType ? getAvailableRideModes(rideModeList, driverTypeList, formData.driverType) : []
                            }
                            {...rest}
                        />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
}

export default CarouselEdit;
