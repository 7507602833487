import { List, Datagrid, TextField, EditButton, ShowButton} from 'react-admin';

const MapList = (props) => {
    return (
        <List {...props}>
            <Datagrid currentSort={null}>
                <TextField source="street" label="Street Name"/>
                <TextField source="radius" label="Radius"/>
                <TextField source="sponsoredMap" label="Sponsored Content"/>
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
}

export default MapList;
