const convertDateFormat = (date) => {
    // Convert from MM/dd/yyyy to yyyy-MM-dd
    let dateArr = date.split("/");
    let year = dateArr.pop();
    // Convert numbers 1 thru 9 to 01 thru 09
    let newDateArr = dateArr.map(num => parseInt(num) < 10 ? "0" + num : num);
    newDateArr.unshift(year);
    return newDateArr.join("-");
}

export default convertDateFormat;