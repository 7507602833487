import { useState } from "react";
import { TextField, Button } from "@material-ui/core"
import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';


const DriverUserConfig = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleTextChange = (event, type) => {
        if (type === "username") {
            setUsername(event.target.value);
        } else if (type === "password") {
            setPassword(event.target.value);
        }
    }

    const getUserData = () => {
        let poolData = {
            UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Your user pool id here
            ClientId: process.env.REACT_APP_WEB_CLIENT_ID, // Your client id here
        };
        let userPool = new CognitoUserPool(poolData);

        let userData = {
            Username: username,
            Pool: userPool,
        };

        let cognitoUser = new CognitoUser(userData);

        let authenticationData = {
            Username: username,
            Password: password,
        };

        let authenticationDetails = new AuthenticationDetails(
            authenticationData
        );

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                let accessToken = result.getAccessToken().getJwtToken();
        
                //POTENTIAL: Region needs to be set if not already set previously elsewhere.
                AWS.config.region = 'us-east-2';

                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: process.env.REACT_APP_DRIVER_CONFIG_IDENTITY_POOL_ID, // your identity pool id here
                    Logins: {
                        // Change the key below according to the specific region your user pool is in.
                        [`cognito-idp.us-east-2.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: result
                            .getIdToken()
                            .getJwtToken(),
                    },
                });
        
                //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                AWS.config.credentials.refresh(error => {
                    if (error) {
                        console.error(error);
                    } else {
                        // Instantiate aws sdk service objects now that the credentials have been updated.
                        // example: var s3 = new AWS.S3();
                        console.log('Successfully logged!');
                        cognitoUser.getUserData((err, userData) => {
                            if (err) {
                                console.log(err.message || JSON.stringify(err));
                                return;
                            }
                            console.table(userData);
                        });
                    }
                });
            },
        
            onFailure: (err) => {
                console.log(err.message || JSON.stringify(err));
            },
        });
    }

    return (
        <div>
            <h1>Driver User Configuration</h1>
            <TextField label="Username" value={username} onChange={(event) => handleTextChange(event, "username")} />
            <TextField type="password" label="Password" value={password} onChange={(event) => handleTextChange(event, "password")} />
            <Button variant="contained" color="primary" onClick={getUserData} >Get User Details</Button>
        </div>
    );
}

export default DriverUserConfig;