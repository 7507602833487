import { Show, SimpleShowLayout, TextField, BooleanField, TabbedShowLayoutTabs, TabbedShowLayout, Tab, ArrayField, Datagrid } from 'react-admin';

const SurveyShow = (props) => {
    return (
        <Show title="Survey View" {...props}>
            <TabbedShowLayout>
                <Tab label="Name">
                    <TextField source="name" label="Name"/>
                    <TextField source="id" label="Id"/>
                    <TextField source="driverType" label="DriverType ID"/>
                    <TextField source="background" label="Background"/>
                </Tab>
                <Tab label="Questions">
                    <ArrayField source="questions" path="questions">
                        <Datagrid>
                            <TextField source="title" label="Title"/>
                            <TextField source="answerType" label="Answer Type"/>
                            
                            <TextField source="options"/>
                            
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export default SurveyShow;