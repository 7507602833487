import { AmplifySignOut } from "@aws-amplify/ui-react";
import { initializeAccessApi, fetchAccessApiInfo } from './actions/accessApiCalls';
import DateFnsUtils from "@date-io/date-fns";
import { Button, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect, useState } from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
import "./App.css";
import convertDateFormat from "./actions/ConvertDateFormat";
import submitReportingQuery from "./actions/SubmitReportingQuery";
import CustomDataProvider from "./components/CustomDataProvider";

import DashboardDev from "./components/DashboardDev";
import DashboardProd from "./components/DashboardProd";
import DashboardUAT from "./components/DashboardUAT";

import DriverTypeCreate from "./components/DriverTypes/DriverTypeCreate";
import DriverTypeEdit from "./components/DriverTypes/DriverTypeEdit";
import DriverTypeList from "./components/DriverTypes/DriverTypeList";
import DriverTypeShow from "./components/DriverTypes/DriverTypeShow";
import DriverUserConfig from "./components/DriverUserConfig";

import MapCreate from "./components/Maps/MapCreate";
import MapEdit from "./components/Maps/MapEdit";
import MapList from "./components/Maps/MapList";
import MapShow from "./components/Maps/MapShow";

import NotificationsCreate from "./components/Notifications/NotificationsCreate";
import NotificationsEdit from "./components/Notifications/NotificationsEdit";
import NotificationsList from "./components/Notifications/NotificationsList";
import NotificationsShow from "./components/Notifications/NotificationsShow";

import CarouselCreate from "./components/Carousels/CarouselCreate";
import CarouselEdit from "./components/Carousels/CarouselEdit";
import CarouselList from "./components/Carousels/CarouselList";
import CarouselShow from "./components/Carousels/CarouselShow";

import PremiumAdsCreate from "./components/PremiumAds/PremiumAdsCreate";
import PremiumAdsEdit from "./components/PremiumAds/PremiumAdsEdit";
import PremiumAdsList from "./components/PremiumAds/PremiumAdsList";
import PremiumAdsShow from "./components/PremiumAds/PremiumAdsShow";

import PublicaAdsCreate from "./components/PublicaAds/PublicaAdsCreate";
import PublicaAdsEdit from "./components/PublicaAds/PublicaAdsEdit";
import PublicaAdsList from "./components/PublicaAds/PublicaAdsList";
import PublicaAdsShow from "./components/PublicaAds/PublicaAdsShow";

import RideModesCreate from "./components/RideModes/RideModesCreate";
import RideModesEdit from "./components/RideModes/RideModesEdit";
import RideModesList from "./components/RideModes/RideModesList";
import RideModesShow from "./components/RideModes/RideModesShow";

import SurveyCreate from "./components/Surveys/SurveyCreate";
import SurveyEdit from "./components/Surveys/SurveyEdit";
import SurveyList from "./components/Surveys/SurveyList";
import SurveyShow from "./components/Surveys/SurveyShow";

import UsersCreate from "./components/Users/UsersCreate";
import UsersEdit from "./components/Users/UsersEdit";
import UsersList from "./components/Users/UsersList";
import UsersShow from "./components/Users/UsersShow";

import CustomSettingsCreate from "./components/CustomSettings/CustomSettingsCreate";
import CustomSettingsEdit from "./components/CustomSettings/CustomSettingsEdit";
import CustomSettingsList from "./components/CustomSettings/CustomSettingsList";
import CustomSettingsShow from "./components/CustomSettings/CustomSettingsShow";
// You can get the current config object
// const currentConfig = Auth.configure();

const fetchJsonDev = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set("x-api-key", process.env.REACT_APP_RIDEMODES_API_KEY_DEV);
  return fetchUtils.fetchJson(url, options);
};

const fetchJsonProd = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set(
    "x-api-key",
    process.env.REACT_APP_RIDEMODES_API_KEY_PROD
  );
  return fetchUtils.fetchJson(url, options);
};

const fetchJsonUat = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set("x-api-key", process.env.REACT_APP_RIDEMODES_API_KEY_UAT);
  return fetchUtils.fetchJson(url, options);
};

//const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");
const dataProviderDev = CustomDataProvider(
  process.env.REACT_APP_RIDEMODES_CONFIG_URL_DEV,
  fetchJsonDev
);
const dataProviderProd = CustomDataProvider(
  process.env.REACT_APP_RIDEMODES_CONFIG_URL_PROD,
  fetchJsonProd
);
const dataProviderUAT = CustomDataProvider(
  process.env.REACT_APP_RIDEMODES_CONFIG_URL_UAT,
  fetchJsonUat
);

const getLiveChannels = (response, videoId) => {
  try {
    return response['live_channels']['live_channel'];
  } catch (err) {
    console.error('Failed to get live channels for videoId ' + videoId)
    return [];
  }
}

const App = () => {
  const [adminMode, setAdminMode] = useState("rideModesConfigProd");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tabletId, setTabletId] = useState("");
  const [numberOfSessions, setNumberOfSessions] = useState(0);
  const [availableAccessChannels, setAvailableAccessChannels] = useState([]);

  const changeStartDate = (date) => setStartDate(date);
  const changeEndDate = (date) => setEndDate(date);
  const handleChangeTabletId = (event) => setTabletId(event.target.value);

  useEffect(() => {
    const getAccessChannels = async () => {
      try {
        const loginInfo = await initializeAccessApi();
        setTimeout(async () => {
          const accessChannelsResp = await fetchAccessApiInfo(loginInfo?.gid, loginInfo?.token, 2000);
          setAvailableAccessChannels(getLiveChannels(accessChannelsResp).filter(item => item.region.toLowerCase() === 'us'));
        }, 1000);
      }
      catch (error) {
        console.error('Failed to fetch available access channels');
      }
    }
    getAccessChannels()
  }, [])

  const changeAdminMode = (mode) => {
    setAdminMode(mode);
  };

  const sendRideModesReportQuery = () => {
    const params = {
      startDate: convertDateFormat(startDate.toLocaleDateString()),
      endDate: convertDateFormat(endDate.toLocaleDateString()),
      tabletId: tabletId && tabletId !== "" ? tabletId : null,
    };
    submitReportingQuery(params).then((res) => setNumberOfSessions(res));
  };

  if (adminMode === "rideModesConfigDev") {
    return (
      <div>
        <div
          style={{
            paddingTop: "40px",
            position: "fixed",
            zIndex: "999",
          }}
        >
          <h3>Dev Environment</h3>
        </div>
        <div
          style={{
            paddingTop: "15px",
          }}
        >
          <h3> </h3>
        </div>
        <Admin dashboard={DashboardDev} dataProvider={dataProviderDev}>
          <Resource
            name="ridemodes"
            list={RideModesList}
            show={RideModesShow}
            create={RideModesCreate}
            edit={RideModesEdit}
          />
          <Resource
            name="users"
            list={UsersList}
            show={UsersShow}
            create={UsersCreate}
            edit={UsersEdit}
          />
          <Resource
            name="maps"
            list={MapList}
            show={MapShow}
            create={MapCreate}
            edit={MapEdit}
          />
          <Resource
            name="drivertypes"
            list={DriverTypeList}
            show={DriverTypeShow}
            create={DriverTypeCreate}
            edit={DriverTypeEdit}
          />
          <Resource
            name="surveys"
            list={SurveyList}
            create={SurveyCreate}
            show={SurveyShow}
            edit={SurveyEdit}
          />
          <Resource
            name="notifications"
            list={NotificationsList}
            create={NotificationsCreate}
            show={NotificationsShow}
            edit={NotificationsEdit}
          />
          <Resource
            name="premium_ads"
            list={PremiumAdsList}
            create={PremiumAdsCreate}
            show={PremiumAdsShow}
          />
          <Resource
             name="publica_ads"
             list={PublicaAdsList}
             create={PublicaAdsCreate}
             show={PublicaAdsShow}
             edit={PublicaAdsEdit}
          />
          <Resource
            name="carousels"
            list={CarouselList}
            create={CarouselCreate}
            show={CarouselShow}
            edit={CarouselEdit}
          />
          <Resource
            name="customSettings"
            list={CustomSettingsList}
            create={CustomSettingsCreate}
            show={CustomSettingsShow}
            edit={CustomSettingsEdit}
          />
        </Admin>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigProd")}
            >
              Ride Modes Configuration Prod
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigUAT")}
            >
              Ride Modes Configuration UAT
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesReporting")}
            >
              Ride Modes Reporting
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("driversConfig")}
            >
              Driver User Configuration
            </Button>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <AmplifySignOut />
          </div>
        </div>
      </div>
    );
  } else if (adminMode === "rideModesConfigProd") {
    return (
      <div>
        <div
          style={{
            paddingTop: "40px",
            position: "fixed",
            zIndex: "999",
          }}
        >
          <h3>Prod Environment</h3>
        </div>
        <div
          style={{
            paddingTop: "15px",
          }}
        >
          <h3> </h3>
        </div>
        <Admin dashboard={DashboardProd} dataProvider={dataProviderProd}>
          <Resource
            name="ridemodes"
            list={RideModesList}
            show={RideModesShow}
            create={RideModesCreate}
            edit={RideModesEdit}
            options={{accessChannels: availableAccessChannels}}
          />
          <Resource
            name="users"
            list={UsersList}
            show={UsersShow}
            create={UsersCreate}
            edit={UsersEdit}
          />
          <Resource
            name="maps"
            list={MapList}
            show={MapShow}
            create={MapCreate}
            edit={MapEdit}
          />
          <Resource
            name="drivertypes"
            list={DriverTypeList}
            show={DriverTypeShow}
            create={DriverTypeCreate}
            edit={DriverTypeEdit}
          />
          <Resource
            name="surveys"
            list={SurveyList}
            create={SurveyCreate}
            show={SurveyShow}
            edit={SurveyEdit}
          />
          <Resource
            name="notifications"
            list={NotificationsList}
            create={NotificationsCreate}
            show={NotificationsShow}
            edit={NotificationsEdit}
          />
          <Resource
            name="premium_ads"
            list={PremiumAdsList}
            create={PremiumAdsCreate}
            show={PremiumAdsShow}
            edit={PremiumAdsEdit}
          />
           <Resource
             name="publica_ads"
             list={PublicaAdsList}
             create={PublicaAdsCreate}
             show={PublicaAdsShow}
             edit={PublicaAdsEdit}
          />
          <Resource
            name="carousels"
            list={CarouselList}
            create={CarouselCreate}
            show={CarouselShow}
            edit={CarouselEdit}
          />
          <Resource
            name="customSettings"
            list={CustomSettingsList}
            create={CustomSettingsCreate}
            show={CustomSettingsShow}
            edit={CustomSettingsEdit}
          />
        </Admin>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigDev")}
            >
              Ride Modes Configuration Dev
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigUAT")}
            >
              Ride Modes Configuration UAT
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesReporting")}
            >
              Ride Modes Reporting
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("driversConfig")}
            >
              Driver User Configuration
            </Button>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <AmplifySignOut />
          </div>
        </div>
      </div>
    );
  } else if (adminMode === "rideModesConfigUAT") {
    return (
      <div>
        <div
          style={{
            paddingTop: "40px",
            position: "fixed",
            zIndex: "999",
          }}
        >
          <h3>UAT Environment</h3>
        </div>
        <div
          style={{
            paddingTop: "15px",
          }}
        >
          <h3> </h3>
        </div>
        <Admin dashboard={DashboardUAT} dataProvider={dataProviderUAT}>
          <Resource
            name="ridemodes"
            list={RideModesList}
            show={RideModesShow}
            create={RideModesCreate}
            edit={RideModesEdit}
          />
          <Resource
            name="users"
            list={UsersList}
            show={UsersShow}
            create={UsersCreate}
            edit={UsersEdit}
          />
          <Resource
            name="maps"
            list={MapList}
            show={MapShow}
            create={MapCreate}
            edit={MapEdit}
          />
          <Resource
            name="drivertypes"
            list={DriverTypeList}
            show={DriverTypeShow}
            create={DriverTypeCreate}
            edit={DriverTypeEdit}
          />
          <Resource
            name="surveys"
            list={SurveyList}
            create={SurveyCreate}
            show={SurveyShow}
            edit={SurveyEdit}
          />
          <Resource
            name="notifications"
            list={NotificationsList}
            create={NotificationsCreate}
            show={NotificationsShow}
            edit={NotificationsEdit}
          />
           <Resource
            name="carousels"
            list={CarouselList}
            create={CarouselCreate}
            show={CarouselShow}
            edit={CarouselEdit}
          />
          <Resource
            name="customSettings"
            list={CustomSettingsList}
            create={CustomSettingsCreate}
            show={CustomSettingsShow}
            edit={CustomSettingsEdit}
          />
        </Admin>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigDev")}
            >
              Ride Modes Configuration Dev
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigProd")}
            >
              Ride Modes Configuration Prod
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigUAT")}
            >
              Ride Modes Configuration UAT
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesReporting")}
            >
              Ride Modes Reporting
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("driversConfig")}
            >
              Driver User Configuration
            </Button>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <AmplifySignOut />
          </div>
        </div>
      </div>
    );
  } else if (adminMode === "rideModesReporting") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Ride Modes Reporting</h1>
        <p>
          Please select a start date and end date. You may also enter an
          optional tablet ID.
        </p>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="start-date"
            label="Start Date"
            value={startDate}
            onChange={changeStartDate}
          />
          <KeyboardDatePicker
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="end-date"
            label="End Date"
            value={endDate}
            onChange={changeEndDate}
          />
        </MuiPickersUtilsProvider>
        <div style={{ padding: "20px" }}>
          <TextField
            id="tablet-id-input"
            label={"Tablet ID"}
            value={tabletId}
            onChange={handleChangeTabletId}
          />
        </div>
        <div style={{ padding: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={sendRideModesReportQuery}
          >
            Submit
          </Button>
        </div>
        <h3>Number of unique sessions for Tablet ID {tabletId}</h3>
        <h2 style={{ paddingBottom: "20px" }}>{numberOfSessions}</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigDev")}
            >
              Ride Modes Configuration Dev
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigProd")}
            >
              Ride Modes Configuration Prod
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("rideModesConfigUAT")}
            >
              Ride Modes Configuration UAT
            </Button>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeAdminMode("driversConfig")}
            >
              Driver User Configuration
            </Button>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <AmplifySignOut />
          </div>
        </div>
      </div>
    );
  } else {
    return <DriverUserConfig />;
  }
};

export default App;
