import { Create, TabbedForm, FormTab, TextInput, NumberInput, ArrayInput, SimpleFormIterator, SelectInput, useNotify, useRefresh, useRedirect, BooleanInput, AutocompleteInput } from 'react-admin';

const RideModesCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(`New ride mode ${data.id} has been created`);
        redirect("/ridemodes");
        refresh();
    }
    return (
        <Create {...props} onSuccess={onSuccess}>
            <TabbedForm>
                <FormTab label="Identifiers">
                    <TextInput source="name" label="Ride Mode Name"/>
                    <TextInput source="nameImage" label="Ride Mode Image"/>
                    <TextInput source="nameInactiveImage" label="Ride Mode Inactive Image"/>
                    <TextInput source="nameSize" label="Ride Mode Image Size"/>
                    <TextInput source="driverType" label="Driver Type"/>
                    <BooleanInput source="isFeatured" label="Featured Ride Mode"/> 
                </FormTab>
                <FormTab label="Rows" path="page-rows">
                    <TextInput source="sponsoredApp.rowPosition" label="Position"/>
                    <TextInput source="sponsoredApp.rowTitle" label="Row Title"/>
                    <TextInput source="sponsoredApp.rowDetails" label="Row Details"/>
                </FormTab>
                <FormTab label="Music Player" path="music-player">
                    <ArrayInput source="musicPlayer.playlistGroups" label="Playlist Groups">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Title"/>
                            <ArrayInput source="playlists" label="Playlists">
                                <SimpleFormIterator>
                                    <TextInput source="title" label="Title"/>
                                    <TextInput source="imagePath" label="Image URL"/>
                                    <TextInput source="srcPath" label="Source URL"/>
                                    <ArrayInput source="songs" label="Songs">
                                        <SimpleFormIterator>
                                            <TextInput source="title" label="Title"/>
                                            <TextInput source="artist" label="Artist"/>
                                            <TextInput source="genre" label="Genre"/>
                                            <TextInput source="cover" label="Cover Image"/>
                                            <TextInput source="src" label="Audio File"/>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Sponsored App" path="sponsored-app">
                    <TextInput source="sponsoredApp.name" label="Name"/>
                    <SelectInput source="sponsoredApp.type" label="App Type" choices={[
                        {
                            id: "Sponsored Video",
                            name: "Sponsored Video"
                        },
                        {
                            id: "Clickable Image Link",
                            name: "Clickable Image Link"
                        },
                        {
                            id: "HTML",
                            name: "HTML"
                        },
                        {
                            id: "Twine",
                            name: "Twine"
                        },
                        {
                            id: "Vimeo",
                            name: "Vimeo"
                        }
                    ]}/>
                    <SelectInput source="sponsoredApp.htmlType" label="Existing HTML Content" choices={[
                     {
                        id: "Groupon Affiliate",
                        name: "Groupon Affiliate"
                    },
                    {
                        id: "Dizzy Affiliate",
                        name: "Dizzy Affiliate"
                    },
                    {
                        id: "Eaze Affiliate",
                        name: "Eaze Affiliate"
                    },
                    {
                        id: "Blueground",
                        name: "Blueground"
                    }
                    ]}/>
                    <SelectInput source="sponsoredApp.twineType" label="Twine Type" choices={[
                     {
                        id: "Live",
                        name: "Live"
                    },
                    {
                        id: "WebApp",
                        name: "Web app"
                    },
                    {
                        id: "Vod",
                        name: "Video on demand"
                    }
                    ]}/>
                    <TextInput source="sponsoredApp.twineName" label="Twine Name"/>
                    <TextInput source="sponsoredApp.videoUrl" label="Video Url"/>
                    <TextInput source="sponsoredApp.image" label="Image"/>
                    <TextInput source="sponsoredApp.style.minHeight" label="Minimum Height"/>
                    <TextInput source="sponsoredApp.style.minWidth" label="Minimum Width"/>
                    <TextInput source="sponsoredApp.style.background" label="Background"/>
                    <TextInput source="sponsoredApp.sponsorColor" label="Color"/>
                </FormTab>
                <FormTab label="Left Sponsor" path="left-sponsor">
                    <TextInput source="sponsors.left.title" label="Title"/>
                    <TextInput source="sponsors.left.image" label="Image"/>
                    <TextInput source="sponsors.left.url" label="Content URL" />
                    <TextInput source="sponsors.left.anchor" label="Anchor URL" />
                    <NumberInput source="sponsors.left.imageWidth" label="Width"/>
                    <NumberInput source="sponsors.left.imageHeight" label="Height"/>
                </FormTab>
                <FormTab label="Right Sponsor" path="right-sponsor">
                    <TextInput source="sponsors.right.title" label="Title"/>
                    <TextInput source="sponsors.right.image" label="Image"/>
                    <TextInput source="sponsors.right.url" label="Content URL"/>
                    <TextInput source="sponsors.right.anchor" label="Anchor URL" />
                    <NumberInput source="sponsors.right.imageWidth" label="Width"/>
                    <NumberInput source="sponsors.right.imageHeight" label="Height"/>
                </FormTab>
                <FormTab label="Bottom Sponsor" path="bottom-sponsor">
                    <TextInput source="sponsors.bottom.title" label="Title"/>
                    <TextInput source="sponsors.bottom.image" label="Image"/>
                    <NumberInput source="sponsors.bottom.imageWidth" label="Width"/>
                    <NumberInput source="sponsors.bottom.imageHeight" label="Height"/>
                </FormTab>
                <FormTab label="Sponsor Page" path="sponsor-page">
                    <TextInput source="sponsors.page.title" label="Title"/>
                    <TextInput source="sponsors.page.body" label="Body" multiline/>
                    <TextInput source="sponsors.page.image" label="Image"/>
                    <TextInput source="sponsors.page.QRCodeValue" label="QR Code"/>
                    <TextInput source="sponsors.page.QRCodeMessage" label="QR Message"/>
                    <TextInput source="sponsors.page.QRCodeLogo" label="QR Logo"/>
                    <NumberInput source="sponsors.page.imageHeight" label="Image Height"/>
                    <NumberInput source="sponsors.page.imageWidth" label="Image Width"/>
                </FormTab>
                <FormTab label="Pay Demo" path="pay-demo">
                    <TextInput source="sponsors.payDemo.headerLeft" label="Left Header"/>
                    <TextInput source="sponsors.payDemo.headerRight" label="Right Header"/>
                    <TextInput source="sponsors.payDemo.bodyLeft" label="Left Body"/>
                    <TextInput source="sponsors.payDemo.bodyRight" label="Right Body"/>
                    <TextInput source="sponsors.payDemo.buttonText" label="Button Text"/>
                    <TextInput source="sponsors.payDemo.QRCodeValue" label="QR Code Value"/>
                    <TextInput source="sponsors.payDemo.backgroundImage" label="Background Image"/>
                </FormTab>
                <FormTab label="Choose Pay" path="choose-pay">
                    <TextInput source="sponsors.choosePay.header" label="Header"/>
                    <TextInput source="sponsors.choosePay.body" label="Body"/>
                    <TextInput source="sponsors.choosePay.QRCodeValue" label="QRCodeValue"/>
                    <TextInput source="sponsors.choosePay.instructionsOne" label="Instructions #1"/>
                    <TextInput source="sponsors.choosePay.instructionsTwo" label="Instructions #2"/>
                </FormTab>
                <FormTab label="Choose Demo" path="choose-demo">
                    <TextInput source="sponsors.chooseDemo.topHeader" label="Top Header"/>
                    <TextInput source="sponsors.chooseDemo.bottomHeader" label="Bottom Header"/>
                </FormTab>
                <FormTab label="Below Fold Rows" path="below-fold-rows">
                    <ArrayInput source="belowFoldRows" label="Below Fold Rows">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Title"/>
                            <SelectInput source="sponsoredApp.type" label="Type" choices={[
                        {
                            id: "Sponsored Video",
                            name: "Sponsored Video"
                        },
                        {
                            id: "Clickable Image Link",
                            name: "Clickable Image Link"
                        },
                        {
                            id: "HTML",
                            name: "HTML"
                        },
                        {
                            id: "Twine",
                            name: "Twine"
                        },
                        {
                            id: "Vimeo",
                            name: "Vimeo"
                        }
                    ]}/>
                        <SelectInput source="sponsoredApp.type" label="HTML Content" choices={[
                        {
                            id: "Groupon Affiliate",
                            name: "Groupon Affiliate"
                        },
                        {
                            id: "Dizzy Affiliate",
                            name: "Dizzy Affiliate"
                        },
                        {
                            id: "Eaze Affiliate",
                            name: "Eaze Affiliate"
                        },
                        {
                            id: "Blueground",
                            name: "Blueground"
                        }
                    ]}/>
                            <NumberInput source="imageWidth" label="Width"/>
                            <NumberInput source="imageHeight" label="Height"/>
                            <ArrayInput source="videos" label="Videos">
                                <SimpleFormIterator>
                                    <TextInput source="title" label="Title"/>
                                    <TextInput source="videoUrl" label="Video URL"/>
                                    <TextInput source="image" label="Image"/>
                                    <TextInput source="sponsoredAppName" label="SponsoredAppName"/>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput source="vimeo" label="Vimeo">
                                <SimpleFormIterator>
                                    <TextInput source="title" label="Title"/>
                                    <TextInput source="vimeoId" label="Vimeo Id"/>
                                    <TextInput source="image" label="Image"/>
                                    <TextInput source="sponsoredAppName" label="SponsoredAppName"/>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput source="html" label="html">
                                <SimpleFormIterator>
                                    <TextInput source="title" label="Title"/>
                                    <TextInput source="htmlImage" label="HTML Thumbnail"/>
                                    <TextInput source="htmlUrl" label="HTML Content"/>
                                    <TextInput source="sponsoredAppName" label="SponsoredAppName"/>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput source="access" label="Access Api">
                                <SimpleFormIterator>
                                    <TextInput source="title" label="Title"/>
                                    <TextInput source="videoTitle" label="Video Title to play"/>
                                    <TextInput source="image" label="Image"/>
                                    <TextInput source="sponsoredAppName" label="SponsoredAppName"/>
                                    <AutocompleteInput 
                                        source="url"
                                        label="Access Url"
                                        choices={props.options.accessChannels}
                                        optionValue="link"
                                    />
                                    <AutocompleteInput 
                                        source="accessImage"
                                        label="Access Image"
                                        choices={props.options.accessChannels}
                                        optionValue="img"
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput source="twine" label="Twine">
                                <SimpleFormIterator>
                                    <TextInput source="title" label="Title"/>
                                    <TextInput source="image" label="Image"/>
                                    <SelectInput source="twineType" label="Twine Type" choices={[
                                        {
                                            id: "Live",
                                            name: "Live"
                                        },
                                        {
                                            id: "WebApp",
                                            name: "Web app"
                                        },
                                        {
                                            id: "Vod",
                                            name: "Video on demand"
                                        }
                                        ]}/>
                                    <TextInput source="twineName" label="Twine Name"/>
                                    <TextInput source="videoUrl" label="Video Url"/>
                                    <TextInput source="sponsoredAppName" label="SponsoredAppName"/>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Audio Source" path="audio-source">
                    <ArrayInput source="sponsoredApp.audioSource.songs" label="Songs">
                        <SimpleFormIterator>
                            <TextInput source="mode" label="Mode"/>
                            <TextInput source="cover" label="Cover Image"/>
                            <TextInput source="title" label="Title"/>
                            <TextInput source="artist" label="Artist"/>
                            <TextInput source="genre" label="Genre"/>
                            <TextInput source="src" label="Audio File"/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Video Source" path="video-source">
                    <ArrayInput source="sponsoredApp.videoSource.videos" label="Videos">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Title"/>
                            <TextInput source="url" label="Video URL"/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Image Source" path="image-source">
                        <ArrayInput source="sponsoredApp.imageSource.images" label="Images">
                        <SimpleFormIterator>
                                <TextInput source="title" label="Title"/>
                                <TextInput source="imageUrl" label="Image URL"/>
                                <TextInput source="navUrl" label="Link URL"/>
                        </SimpleFormIterator>
                        </ArrayInput>
                </FormTab>
                <FormTab label="HTML Source" path="html-source">
                        <ArrayInput source="sponsoredApp.htmlSource.html" label="HTML">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Title"/>
                            <TextInput source="htmlImage" label="HTML Thumbnail"/>
                            <TextInput source="htmlUrl" label="HTML Content"/>
                        </SimpleFormIterator>
                        </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    );
}
export default RideModesCreate;