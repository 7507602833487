import { Edit, SimpleForm, TextInput, BooleanInput, SelectInput, useNotify, useRefresh, useRedirect } from 'react-admin';

const MapEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify(`Map ${data.id} has been updated`);
        redirect("/maps");
        refresh();
    }

    return (
        <Edit {...props} mutationMode="pessimistic" onSuccess={onSuccess}>
            <SimpleForm>
                <TextInput disabled source="id" label="Id"/>
                <TextInput source="street" label="Street Name"/>
                <TextInput source="radius" label="Radius"/>
                <TextInput source="sponsoredMap" label="Sponsored Content"/>
            </SimpleForm>
        </Edit>
    );
}

export default MapEdit;