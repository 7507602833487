import { makePickerWithState } from '@material-ui/pickers';
import {
    Edit,
    TextInput,
    SimpleForm,
    useNotify,
    useRefresh,
    useRedirect,
    SelectInput,
    NumberInput
} from 'react-admin';

import {useEffect, useState} from "react";
import getDriverTypes from "../../actions/GetDriverTypes";

const NotificationsEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [driverTypeList, setDriverTypeList] = useState([{id: '1', name: '2'}]);

    const onSuccess = ({ data }) => {
        notify(`New notification ${data.id} has been created`);
        redirect("/notifications");
        refresh();
    }

    useEffect(() => {
        getDriverTypes(props.adminMode).then((result => {
            setDriverTypeList(result);
        }))
    }, []);

    return (
        <Edit {...props} mutationMode="pessimistic" onSuccess={onSuccess}>
            <SimpleForm>
                <TextInput source="title"/>
                <SelectInput source="driverType" label="Driver Type" choices={driverTypeList}/>
                <SelectInput source="triggerType" label="Trigger Type" choices={[
                    {
                        id: "Time",
                        name: "Time"
                    }
                ]}/>
                <NumberInput source="triggerDuration" label="Trigger Duration (Seconds)"/>
                <SelectInput source="triggerMethod" label="Trigger Method" choices={[
                    {
                        id: "afterSessionStart",
                        name: "After Session Start"
                    }
                ]}/>
                <NumberInput source="displayDuration" label="Display Duration (Seconds)"/>
                <TextInput source="backgroundColor" label="Notification Background Color"/>
                <SelectInput source="contentType" label="Content Type" choices={[
                    {
                        id: "html",
                        name: "HTML"
                    },
                    {
                        id: 'microSurvey',
                        name: 'Micro Survey'
                    }
                ]}/>
                <TextInput source="contentBody" label="Content" multiline fullWidth/>
                <TextInput source="microSurveyQuestion" label="Micro Survey Question"/>
            </SimpleForm>
        </Edit>
    );
}

export default NotificationsEdit;
