import {
    Datagrid,
    List,
    useRecordContext,
    EditButton,
    TextField
  } from "react-admin";
  
  import { useEffect, useState } from "react";
  import getDriverTypes from "../../actions/GetDriverTypes";
  
  /**
   * id: uid;
   * settingScope: string;
   * settingValue: string
   * identity: string;
   * */
  
  const CustomSettingsList = (props) => {
    const [driverTypeList, setDriverTypeList] = useState([{ id: "", name: "" }]);
    useEffect(() => {
      getDriverTypes(props.adminMode).then((result) => {
        setDriverTypeList(result);
      });
    }, []);
  
    const Identity = (props) => {
      const record = useRecordContext(props);
      let r = "";
        
      driverTypeList.forEach((item) => {
        if (record.driverType === item.id) {
          r = item.name;
        }
      });
      return <span>{r}</span>;
    };
  
    return (
      <List {...props}>
        <Datagrid currentSort={null}>
          <TextField source="settingScope" label="Scope" />
          <TextField source="identity" label="Scope Identity" />
          <TextField source="settingName" label="Setting Name" />
          <TextField source="settingValue" label="Setting Value" />
          <EditButton /> 
        </Datagrid>
      </List>
    );
  };
  
  export default CustomSettingsList;
  